
import { Component, Vue } from 'vue-property-decorator';
import SingleHeader from '@/components/singleHeader.vue';
import SingleFooter from '@/components/singleFooter.vue';

@Component({
    name: "SampleCenter",
    components: {
        SingleHeader,
        SingleFooter,
    },
})
export default class SampleCenter extends Vue {
    private apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private activeIndex: any = 0; // 选中栏目的标识
    private sampleLabelList: any = [];
    private sampleList: any = [];
    private mounted() {
        // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0, 0);
    }

    private created() {
        this.getSampleLabelList();
        this.getSampleList();
    }

    private getSampleLabelList() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/mall/wis3d/sample/category')
        .then((res: any) => {
            this.sampleLabelList = res;
            this.sampleLabelList.splice(0, 0, {
                id: 0,
                name: "全部",
            });
        });
    }
    private getSampleList() {
        const paramData: any = {};
        if (this.activeIndex !== 0) {
            paramData.category = this.activeIndex;
        }
        paramData.notLoading = true;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/mall/wis3d/sample')
        .then((res: any) => {
            this.sampleList = res;
        });
    }
    // 选中标签
    private select(id: any) {
        if (id !== this.activeIndex) {
            this.activeIndex = id;
            this.getSampleList();
        }
    }
}

